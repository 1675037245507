<template>
	<div
		v-if="notification"
		:id="id"
		:class="['notification', type, position, size, { dark: dark, closable: closable }]"
		@mouseenter="keepPopup"
		@mouseleave="hidePopup"
		ref="popup"
	>
		<TnIcon
			v-if="!hideIcon"
			:name="icon"
			:size="iconSize"
			:aria-label="`Notification type: ${type}`"
			class="notification-icon"
		/>
		<div class="notification-content-wrapper">
			<span class="notification-content-title">{{ title }}</span>
			<div
				class="notification-content"
				v-if="$slots['default']"
			>
				<slot />
			</div>
		</div>
		<TnButton
			v-if="closable"
			style="margin-left: auto"
			class="close-button"
			icon-only="remove"
			size="xs"
			tertiary
			:dark="dark"
			@click="clearPopup"
			@keyup.enter="clearPopup"
			@keyup.space="clearPopup"
			@blur="hidePopup"
			@focus="keepPopup"
			aria-label="Lukk"
			tabindex="0"
		></TnButton>
	</div>
</template>

<script>
import positions from "./definitions/positions";
import sizes from "./definitions/sizes";
import types from "./definitions/notificationTypes";
import {
	colorInformation200Tint,
	colorInformation800Shade,
	colorSuccess200Tint,
	colorSuccess800Shade,
	colorWarning200Tint,
	colorWarning800Shade,
	colorAttention200Tint,
	colorAttention800Shade,
	colorCritical200Tint,
	colorCritical800Shade,
} from "@/assets/tokens/js/es6";

/**
 * Notification is used to give general user feedback or feedback based on user-action
 * @displayName TnNotification
 */
export default defineComponent({
	name: "TnNotification",

	props: {
		/**
		 * Toggles show or hide notification
		 */
		show: {
			type: Boolean,
			default: true,
		},
		/**
		 * The style of the notification
		 * @values info, info-gray, success, warning, error, attention
		 */
		type: {
			type: String,
			default: "info",
			validator: function (value) {
				if (value) return types.indexOf(value.toLowerCase()) !== -1;
				return true;
			},
		},
		/**
		 * The content of the notification
		 */
		title: {
			type: String,
			default: "",
		},
		/**
		 * The position of the popup
		 * @values top-left, top-right, bottom-right, bottom-left, centered-top, centered-bottom
		 */
		position: {
			type: String,
			default: "inline",
			validator: function (value) {
				if (value) return positions.indexOf(value.toLowerCase()) !== -1;
				return true;
			},
		},
		/**
		 * The timeout of the notification before fading out. Default 0 and lowest possible value = 2500 milliseconds.
		 */
		timeout: {
			type: [Number, String],
			default: 0,
		},
		/**
		 * Dark
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Closable
		 */
		closable: {
			type: Boolean,
			default: false,
		},
		/**
		 * Size
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "l",
			validator: function (value) {
				return sizes.includes(value.toLowerCase()) || "l";
			},
		},
		/**
		 * Hides the UI icon
		 */
		hideIcon: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		/**
		 * Event fired when Notification is closed
		 */
		"close",
	],

	data() {
		return {
			popup: true,
			popupTimer: null,
			animationTimeout: null,
		};
	},

	computed: {
		icon() {
			switch (this.type.toLowerCase()) {
				case "info":
					return "info-circle-outline";
				case "info-gray":
					return "info-circle-outline";
				case "success":
					return "success-circle-outline";
				case "warning":
				case "attention":
					return "warning-circle-outline";
				case "error":
					return "critical-outline";
				default:
					return "info-circle-outline";
			}
		},
		iconSize() {
			if (this.size !== "s") return this.size;
			else return "m";
		},
		color() {
			switch (this.type.toLowerCase()) {
				case "info":
					return this.dark ? colorInformation200Tint : colorInformation800Shade;
				case "info-gray":
					return this.dark ? colorInformation200Tint : colorInformation800Shade;
				case "success":
					return this.dark ? colorSuccess200Tint : colorSuccess800Shade;
				case "warning":
					return this.dark ? colorWarning200Tint : colorWarning800Shade;
				case "attention":
					return this.dark ? colorAttention200Tint : colorAttention800Shade;
				case "error":
					return this.dark ? colorCritical200Tint : colorCritical800Shade;
			}
		},
		notification() {
			return this.popup;
		},
		id() {
			return useId();
		},
	},

	mounted() {
		if (this.timeout <= 0) return;
		this.hidePopup();
	},

	methods: {
		hidePopup() {
			if (this.timeout <= 0) return;
			const notification = this.$refs.popup;
			if (this.timeout < 2500) this.timeout = 2500;
			this.animationTimeout = setTimeout(() => {
				notification.classList.add("fadeOut");
			}, this.timeout);
			this.popupTimer = setTimeout(() => {
				this.popup = false;
			}, this.timeout + 1500);
		},
		keepPopup() {
			// let remainingTimer = this.
			const notification = document.getElementById(this.id);
			notification?.classList.remove("fadeOut");
			clearTimeout(this.popupTimer);
			clearTimeout(this.animationTimeout);
			this.popup = true;
		},
		clearPopup() {
			this.$emit("close");
			this.popup = false;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/scss/mixins" as typoMixins;
@use "@/assets/global-style/scss/mixins/shadow" as shadow;

.notification {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	border-radius: 8px;
	box-sizing: border-box;
	padding: variables.$spacing-m;
	position: fixed;
	gap: variables.$spacing-m;

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	&:not(.inline) {
		z-index: 6900;

		@include shadow.shadow(l);
	}

	&-content {
		&-wrapper {
			display: flex;
			flex-direction: column;
		}
	}

	&.s {
		@include typoMixins.font-text-xs;

		.notification {
			&-content {
				margin-top: variables.$spacing-2xs;
			}

			&-content-title {
				@include typoMixins.font-text-bold-s;

				@media screen and (max-width: variables.$size-screen-phone) {
					@include typoMixins.font-text-bold-xs;
				}
			}

			&-icon {
				margin-top: 0;
			}
		}
	}

	&.m {
		@include typoMixins.font-text-s;

		.notification {
			&-content {
				margin-top: variables.$spacing-xs;
			}

			&-content-title {
				@include typoMixins.font-text-bold-m;

				@media screen and (max-width: variables.$size-screen-phone) {
					@include typoMixins.font-text-bold-s;
				}
			}

			&-icon {
				margin-top: 1px;
			}
		}
	}

	&.l {
		@include typoMixins.font-text-m;

		.notification {
			&-content {
				margin-top: variables.$spacing-s;
			}

			&-content-title {
				@include typoMixins.font-text-bold-l;

				@media screen and (max-width: variables.$size-screen-phone) {
					@include typoMixins.font-text-bold-m;
				}
			}

			&-icon {
				margin-top: -1px;
			}
		}
	}

	.focus:focus {
		outline: 0;
		border-radius: 4px;
		box-shadow: 0 0 0 2px variables.$color-cta-focus;
	}

	&.top-left {
		top: variables.$spacing-s;
		left: variables.$spacing-s;
	}

	&.top-right {
		top: variables.$spacing-s;
		right: variables.$spacing-s;
	}

	&.bottom-right {
		bottom: variables.$spacing-s;
		right: variables.$spacing-s;
	}

	&.bottom-left {
		bottom: variables.$spacing-s;
		left: variables.$spacing-s;
	}

	&.centered-top {
		top: variables.$spacing-s;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: fit-content;

		@media screen and (max-width: variables.$size-screen-xs) {
			max-width: 100%;
			top: 0;
		}
	}

	&.centered-bottom {
		bottom: variables.$spacing-s;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: fit-content;

		@media (max-width: variables.$size-screen-xs) {
			max-width: 100%;
			bottom: 0;
		}
	}

	&.inline {
		position: relative;
		width: 100%;
	}

	&.fadeOut {
		animation: fadeOut 0.7s forwards;
	}

	&.info {
		background-color: variables.$color-information-50-tint;
		color: variables.$color-information-800-shade;

		.notification-icon {
			color: variables.$color-information-500-core;
		}

		&.dark {
			background-color: variables.$color-information-800-shade;
			color: variables.$color-information-50-tint;
			border: 1px solid variables.$color-information-500-core;

			.notification-icon {
				color: variables.$color-information-200-tint;
			}
		}
	}

	&.info-gray {
		background-color: variables.$color-neutrals-25-tint;
		color: variables.$color-neutrals-800-shade;

		.notification-icon {
			color: variables.$color-information-500-core;
		}

		&.dark {
			background-color: variables.$color-neutrals-700-shade;
			color: variables.$color-neutrals-50-tint;
			border: 1px solid variables.$color-neutrals-500-core;

			.notification-icon {
				color: variables.$color-information-200-tint;
			}
		}
	}

	&.success {
		background-color: variables.$color-success-50-tint;
		color: variables.$color-success-800-shade;

		.notification-icon {
			color: variables.$color-success-500-core;
		}

		&.dark {
			background-color: variables.$color-success-800-shade;
			color: variables.$color-success-50-tint;
			border: 1px solid variables.$color-success-500-core;

			.notification-icon {
				color: variables.$color-success-200-tint;
			}
		}
	}

	&.warning {
		background-color: variables.$color-warning-50-tint;
		color: variables.$color-warning-800-shade;

		.notification-icon {
			color: variables.$color-warning-500-core;
		}

		&.dark {
			background-color: variables.$color-warning-800-shade;
			color: variables.$color-warning-50-tint;
			border: 1px solid variables.$color-warning-500-core;

			.notification-icon {
				color: variables.$color-warning-200-tint;
			}
		}
	}

	&.attention {
		background-color: variables.$color-attention-50-tint;
		color: variables.$color-attention-800-shade;

		.notification-icon {
			color: variables.$color-attention-800-shade;
		}

		&.dark {
			background-color: variables.$color-attention-800-shade;
			color: variables.$color-attention-50-tint;
			border: 1px solid variables.$color-attention-500-core;

			.notification-icon {
				color: variables.$color-attention-200-tint;
			}
		}
	}

	&.error {
		background-color: variables.$color-critical-50-tint;
		color: variables.$color-critical-800-shade;

		.notification-icon {
			color: variables.$color-critical-500-core;
		}

		&.dark {
			background-color: variables.$color-critical-800-shade;
			color: variables.$color-critical-50-tint;
			border: 1px solid variables.$color-critical-500-core;

			.notification-icon {
				color: variables.$color-critical-200-tint;
			}
		}
	}

	&-icon {
		flex-shrink: 0;
	}

	@media screen and (max-width: variables.$size-screen-phone) {
		@include typoMixins.font-text-s;
	}
}
</style>
